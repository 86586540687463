import React from "react";
import { Root } from "./style";

import Layout from "components/Layout/Dashboard";
import Transactions from "components/Common/Transactions";

import { inWindow } from "utils";

const DashboardTransactions = ({ location }) => {
  return (
    <Layout location={location}>
      {inWindow() ? (
        <>
          <Transactions
            location={location}
            type="transactions"
            includeType={true}
            only="list"
            round={true}
          />
        </>
      ) : (
        ""
      )}
    </Layout>
  );
};

export default DashboardTransactions;
